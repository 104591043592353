body {
  margin: 0;
  font-family: 'Architects Daughter', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Architects Daughter', cursive;
}

/* Scroll bar width */
body::-webkit-scrollbar {
  width: 5px;
}

/* color of scroll area */
body::-webkit-scrollbar-track {
  background-color: #414345;
}

/* Color of actual scroll bar */
body::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(90deg, #9867f0 -6.8%, #ed4e50 84.14%);
  outline: 1px solid #414345;
}
