.github-projects-root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.card {
  width: 600px;
  height: 400px;
  overflow-y: scroll;

  border-radius: 25px;
  padding: 10px;

  border: 1px solid #9867f0;
  background-color: #0d1117 !important;
  color: #8b949e !important;
}

/* Scroll bar width */
.card::-webkit-scrollbar {
  width: 5px;
}

/* Color of actual scroll bar */
.card::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(90deg, #9867f0 -6.8%, #ed4e50 84.14%);
  outline: 1px solid #414345;
}

.title {
  color: #c9d1d9 !important;
}

.markdown {
  overflow-x: scroll;
}


/* Scroll bar width */
.markdown::-webkit-scrollbar {
  width: 5px;
}

/* Color of actual scroll bar */
.markdown::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(90deg, #9867f0 -6.8%, #ed4e50 84.14%);
  outline: 1px solid #414345;
}